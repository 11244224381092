<template>
	<v-sheet class="main-detail">
		<div class="">
			<v-layout class="section-main-content">
				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="align-center light-blue-bg pa-2 flex-wrap">
							<v-flex md12 class="d-flex flex-wrap align-center">
								<h4 class="fw-500 title-text black--text mb-0">
									{{ getValue(seo_detail, "project.sales_person.display_name") }}
								</h4>
								<p
									class="my-auto custom-title font-level-3-bold fw-600 text-truncate"
									style="max-width: 757px; margin-top: -4px !important; font-size: 20px !important"
								>
									{{ getValue(seo_detail, "customer_urls.0.website_urls") }}
								</p>
								<v-spacer></v-spacer>
								<v-btn depressed tile class="my-auto" v-on:click="backToListing">
									<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
									back
								</v-btn>
							</v-flex>
						</v-layout>
						<v-layout class="pa-2 pb-0">
							<v-tabs
								v-model="seoTab"
								background-color="transparent"
								color="blue"
								class="custom-tab-transparent w-100"
								active-class="blue darken-4 text-white"
								hide-slider
							>
								<!-- Hide Tab when nothing is going to show -->
								<v-tab href="#overview"><v-icon small left>mdi-information-outline</v-icon>Overview</v-tab>
								<v-tab href="#ranking"><v-icon small left>mdi-google-analytics</v-icon>Ranking</v-tab>
								<v-tab href="#ranking_reports">
									<v-icon small left>mdi-file-table</v-icon>
									Ranking reports
								</v-tab>

								<v-tab href="#keyword_analysis">
									<v-icon small left>mdi-file-table</v-icon>
									Keyword Analysis
								</v-tab>
								<v-tab href="#keyword_history">
									<v-icon small left>mdi-file-table</v-icon>
									Keyword History
								</v-tab>
								<v-tab href="#analytics_frame">
									<v-icon small left>mdi-check-circle-outline</v-icon>
									Analytics
								</v-tab>
							</v-tabs>
						</v-layout>
					</div>
					<div class="tab-layout-content project-tb-content px-2">
						<v-tabs-items v-model="seoTab">
							<v-tab-item
								v-if="seoTab == 'overview'"
								value="overview"
								class="d-flex justify-space-between"
							>
								<div style="width: 100%">
									<v-row class="d-flex justify-space-between" style="width: 100%">
										<v-col md="6">
											<div class="detail-card bg-white">
												<div class="card--header d-flex align-items-center grey lighten-5 pa-2 border-bottom">
													<div>
														<span class="font-level-3-bold"> About </span>
													</div>
												</div>
												<div style="min-height: 100%" class="card--body">
													<table class="w-70">
														<tr>
															<td
																class="fw-600 px-3 py-2"
																style="min-width: 315px !important; width: 315px !important"
															>
																Business Name
															</td>
															<td class="fw-500 px-3 py-1">
																<ShowValue
																	:object="data.about"
																	object-key="business_name"
																	label="business name"
																></ShowValue>
															</td>
														</tr>
														<tr>
															<td
																class="fw-600 px-3 py-2"
																style="min-width: 315px !important; width: 315px !important"
															>
																Phone
															</td>
															<td class="fw-500 px-3 py-1">
																<ShowValue :object="data.about" object-key="phone" label="Phone"></ShowValue>
															</td>
														</tr>

														<tr>
															<td
																class="fw-600 px-3 py-2"
																style="min-width: 315px !important; width: 315px !important"
															>
																Email
															</td>
															<td class="fw-500 px-3 py-1">
																<div class="cursor-pointer">
																	<ShowValue
																		:object="data.about"
																		:capitalize="false"
																		object-key="email"
																		label="Email"
																	></ShowValue>
																</div>
															</td>
														</tr>

														<tr>
															<td
																class="fw-600 px-3 py-2"
																style="min-width: 315px !important; width: 315px !important"
															>
																Address
															</td>
															<td class="fw-500 px-3 py-1">
																<ShowValue :object="data.about" object-key="address" label="Address"></ShowValue>
															</td>
														</tr>

														<tr>
															<td
																class="fw-600 px-3 py-2"
																style="min-width: 315px !important; width: 315px !important"
															>
																CMS
															</td>

															<td class="fw-500 px-3 py-1" style="text-transform: capitalize">
																<template v-if="data.about.CMS">
																	<v-tooltip left>
																		<template #activator="{ on, attrs }">
																			<div class="d-flex align-center">
																				<inline-svg
																					v-if="data.about.CMS == 'wix'"
																					style="width: 25px; height: 25px"
																					:src="$assetURL('media/custom-svg/wix-cms.svg')"
																					v-on="on"
																					v-bind="attrs"
																				/>
																				<inline-svg
																					v-else-if="data.about.CMS == 'wordpress'"
																					style="width: 25px; height: 25px"
																					:src="$assetURL('media/custom-svg/wordpress-cms.svg')"
																					v-on="on"
																					v-bind="attrs"
																				/>
																				<inline-svg
																					v-else-if="data.about.CMS == 'shopify'"
																					style="width: 25px; height: 25px"
																					:src="$assetURL('media/custom-svg/shopify-cms.svg')"
																					v-on="on"
																					v-bind="attrs"
																				/>
																				<p class="mb-0 ml-1 text-capitalize">
																					{{ data.about.CMS == "other" ? data.about.other_cms : data.about.CMS }}
																				</p>
																			</div>
																		</template>
																		<span class="text-capitalize">{{ data.about.CMS }}</span>
																	</v-tooltip>
																</template>
																<template v-else>
																	<em class="text-muted text-lowercase">no cms</em>
																</template>
															</td>
														</tr>
														<tr>
															<td
																class="fw-600 px-3 py-2"
																style="min-width: 315px !important; width: 315px !important"
															>
																Account Country
															</td>
															<td class="fw-500 px-3 py-1 d-flex align-center">
																<div
																	v-if="
																		data.about &&
																		data.about.account_country &&
																		country_flag[data.about.account_country]
																	"
																	class="vti__flag mr-2"
																	:class="country_flag[data.about.account_country]"
																/>
																<ShowValue
																	:object="data.about"
																	object-key="account_country"
																	label="Account Country"
																></ShowValue>
															</td>
														</tr>
														<tr>
															<td
																class="fw-600 px-3 py-2"
																style="min-width: 315px !important; width: 315px !important"
															>
																Category
															</td>
															<td class="fw-500 px-3 py-1 d-flex align-center">
																<template v-if="data.about && data.about.category">{{
																	mod_string(data.about.category, "_", " ")
																}}</template>
																<template v-else><em class="text-muted">no Category</em></template>
															</td>
														</tr>
														<!-- <tr>
															<td
																class="fw-600 px-3 py-2"
																style="min-width: 315px !important; width: 315px !important"
															>
																Domain
															</td>
															<td class="fw-500 px-3 py-1 d-flex align-center">
																<ShowValue
																	:object="urlData"
																	object-key="website_urls"
																	:capitalize="false"
																	label="Domain URL"
																></ShowValue> 
															</td>
														</tr>
														<tr>
															<td
																class="fw-600 px-3 py-2"
																style="min-width: 315px !important; width: 315px !important"
															>
																Target Country
															</td>
															<td class="fw-500 px-3 py-1 d-flex align-center">
																<span
																	v-if="
																		urlData &&
																		(urlData.other_target_google ||
																			(urlData.url_target_google && urlData.url_target_google.id))
																	"
																	>{{
																		(urlData.url_target_google.value == "other" ||
																			urlData.url_target_google.id == 216) &&
																		urlData.other_target_google
																			? urlData.other_target_google
																			: urlData.url_target_google.text
																	}}</span
																>
																<em v-else class="text-muted">no target country</em>
															</td>
														</tr> -->
													</table>
												</div>
											</div>
										</v-col>

										<v-col md="6" class="detail-card mb-8">
											<div class="card--header bg-white grey lighten-5 d-flex align-items-center pa-2">
												<div class="font-level-3-bold">Promised KPI</div>
											</div>
											<div class="card--body px-3" style="min-height: 100%">
												<v-row class="d-flex justify-space-between">
													<v-col md="12" class="promised-kpi-table">
														<table class="mt-2" style="width: 100%">
															<thead>
																<tr>
																	<th class="fw-600"></th>
																	<th colspan="2" class="fw-600 text-center">Promised</th>
																	<th class="fw-600 text-center">Current</th>
																</tr>
																<tr>
																	<th></th>
																	<th class="fw-600 text-center">6 Month</th>
																	<th class="fw-600 text-center">12 Month</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td class="fw-600 px-3 py-2">Top 3</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.kpi_6_month_top_3")
																				? getValue(data, "kpi.toCustomer.kpi_6_month_top_3")
																				: "-"
																		}}
																	</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.top_3")
																				? getValue(data, "kpi.toCustomer.top_3")
																				: "-"
																		}}
																	</td>
																	<td
																		class="fw-500 px-3 py-1 text-center"
																		:class="[
																			getValue(data, 'kpi.toCustomer.kpi_6_month_top_3')
																				? kpiColorIdentifier(
																						getValue(data, 'kpi.toCustomer.kpi_6_month_top_3'),
																						getValue(data, 'kpi.toCustomer.top_3'),
																						getValue(current_rank, 'top_3')
																				  )
																				: '',
																		]"
																	>
																		{{ getValue(current_rank, "top_3") ? getValue(current_rank, "top_3") : "-" }}
																	</td>
																</tr>
																<tr class="promised-kpi-table-tr">
																	<td class="fw-600 px-3 py-2">Top 5</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.kpi_6_month_top_5")
																				? getValue(data, "kpi.toCustomer.kpi_6_month_top_5")
																				: "-"
																		}}
																	</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.top_5")
																				? getValue(data, "kpi.toCustomer.top_5")
																				: "-"
																		}}
																	</td>
																	<td
																		class="fw-500 px-3 py-1 text-center"
																		:class="[
																			kpiColorIdentifier(
																				getValue(data, 'kpi.toCustomer.kpi_6_month_top_5'),
																				getValue(data, 'kpi.toCustomer.top_5'),
																				getValue(current_rank, 'top_5')
																			),
																		]"
																	>
																		{{ getValue(current_rank, "top_5") ? getValue(current_rank, "top_5") : "-" }}
																	</td>
																</tr>
																<tr>
																	<td class="fw-600 px-3 py-2">Top 10</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.kpi_6_month_top_10")
																				? getValue(data, "kpi.toCustomer.kpi_6_month_top_10")
																				: "-"
																		}}
																	</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.top_10")
																				? getValue(data, "kpi.toCustomer.top_10")
																				: "-"
																		}}
																	</td>
																	<td
																		class="fw-500 px-3 py-1 text-center"
																		:class="[
																			kpiColorIdentifier(
																				getValue(data, 'kpi.toCustomer.kpi_6_month_top_10'),
																				getValue(data, 'kpi.toCustomer.top_10'),
																				getValue(current_rank, 'top_10')
																			),
																		]"
																	>
																		{{ getValue(current_rank, "top_10") ? getValue(current_rank, "top_10") : "-" }}
																	</td>
																</tr>
																<tr class="promised-kpi-table-tr">
																	<td class="fw-600 px-3 py-2">Top 20</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.kpi_6_month_top_20")
																				? getValue(data, "kpi.toCustomer.kpi_6_month_top_20")
																				: "-"
																		}}
																	</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.top_20")
																				? getValue(data, "kpi.toCustomer.top_20")
																				: "-"
																		}}
																	</td>
																	<td
																		class="fw-500 px-3 py-1 text-center"
																		:class="[
																			kpiColorIdentifier(
																				getValue(data, 'kpi.toCustomer.kpi_6_month_top_20'),
																				getValue(data, 'kpi.toCustomer.top_20'),
																				getValue(current_rank, 'top_20')
																			),
																		]"
																	>
																		{{ getValue(current_rank, "top_20") ? getValue(current_rank, "top_20") : "-" }}
																	</td>
																</tr>
																<tr>
																	<td class="fw-600 px-3 py-2">Top 50</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.kpi_6_month_top_50")
																				? getValue(data, "kpi.toCustomer.kpi_6_month_top_50")
																				: "-"
																		}}
																	</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.top_50")
																				? getValue(data, "kpi.toCustomer.top_50")
																				: "-"
																		}}
																	</td>
																	<td
																		class="fw-500 px-3 py-1 text-center"
																		:class="[
																			kpiColorIdentifier(
																				getValue(data, 'kpi.toCustomer.kpi_6_month_top_50'),
																				getValue(data, 'kpi.toCustomer.top_50'),
																				getValue(current_rank, 'top_50')
																			),
																		]"
																	>
																		{{ getValue(current_rank, "top_50") ? getValue(current_rank, "top_50") : "-" }}
																	</td>
																</tr>
																<tr class="promised-kpi-table-tr">
																	<td class="fw-600 px-3 py-2">Top 100</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.kpi_6_month_top_100")
																				? getValue(data, "kpi.toCustomer.kpi_6_month_top_100")
																				: "-"
																		}}
																	</td>
																	<td class="fw-500 px-3 py-1 text-center">
																		{{
																			getValue(data, "kpi.toCustomer.top_100")
																				? getValue(data, "kpi.toCustomer.top_100")
																				: "-"
																		}}
																	</td>
																	<td
																		class="fw-500 px-3 py-1 red--text text-center"
																		:class="[
																			kpiColorIdentifier(
																				getValue(data, 'kpi.toCustomer.kpi_6_month_top_100'),
																				getValue(data, 'kpi.toCustomer.top_100'),
																				getValue(current_rank, 'top_100')
																			),
																		]"
																	>
																		{{ getValue(current_rank, "top_100") ? getValue(current_rank, "top_100") : "-" }}
																	</td>
																</tr>
															</tbody>
														</table>
													</v-col>
												</v-row>
											</div>
										</v-col>

										<v-col md="6">
											<div style="min-height: 100%" class="detail-card bg-white mb-8">
												<div class="card--header d-flex align-items-center grey lighten-5 pa-2 border-bottom">
													<div class="font-level-3-bold">Contract</div>
												</div>
												<div style="min-height: 100%" class="card--body">
													<table class="w-70">
														<tr>
															<td class="fw-600 px-3 py-2">Start Date</td>
															<td class="fw-500 px-3 py-1">
																<template v-if="seo_detail && seo_detail.select_date">
																	{{ formatDate(seo_detail.select_date) }}</template
																>
																<template v-else> <em class="text-muted">no end date</em></template>
															</td>
														</tr>
														<tr>
															<td class="fw-600 px-3 py-2">End Date</td>
															<td class="fw-500 px-3 py-1">
																<template v-if="seo_detail && seo_detail.time_free_end">
																	{{ formatDate(seo_detail.time_free_end) }}</template
																>
																<template v-else> <em class="text-muted">no end date</em></template>
															</td>
														</tr>
														<tr>
															<td class="fw-600 px-3 py-2" style="min-width: 270px">Reporting Date</td>
															<td class="fw-500 px-3" style="min-width: 250px">
																<div class="py-3 d-flex justify-space-between">
																	<div v-if="seo_detail && seo_detail.reporting_date" class="my-auto">
																		{{ seo_detail.reporting_date }}
																	</div>
																	<template v-else> <em class="text-muted">no reporting date</em></template>
																</div>
															</td>
														</tr>
														<tr>
															<td class="fw-600 px-3 py-2">Months Passed</td>
															<td class="fw-500 px-3 py-1">
																<ShowValue
																	:object="seo_detail"
																	object-key="month_passed"
																	label="Month passed"
																></ShowValue>
															</td>
														</tr>
														<tr>
															<td class="fw-600 px-3 py-2">Months Remaining</td>
															<td class="fw-500 px-3 py-1">
																<ShowValue
																	:object="seo_detail"
																	object-key="month_remaining"
																	label="Month Remaining"
																></ShowValue>
															</td>
														</tr>
													</table>
												</div>
											</div>
										</v-col>

										<v-col md="6" class="detail-card mb-8">
											<div class="card--header bg-white grey lighten-5 d-flex align-items-center pa-2">
												<div class="font-level-3-bold">Competitors</div>
											</div>
											<div class="card--body" style="min-height: 100%">
												<table class="w-100">
													<tr v-for="(comp, index) in data?.competitors" :key="'Competetors_' + index">
														<td class="fw-600 px-3 py-2" style="width: 295px !important">{{ index + 1 }}</td>
														<td class="fw-500 px-3 py-1">
															<span>{{ comp }}</span>
														</td>
													</tr>
												</table>
											</div>
										</v-col>
									</v-row>
								</div>
							</v-tab-item>

							<v-tab-item v-if="seoTab == 'ranking'" value="ranking" class="d-flex justify-space-between">
								<DomainRankingTab
									:seo-id="seo_detail.id"
									:project-id="getValue(seo_detail, 'project.id')"
									user-type="customer"
								/>
							</v-tab-item>

							<v-tab-item
								v-if="seoTab == 'ranking_reports' && seo_detail?.id && seo_detail.project"
								value="ranking_reports"
							>
								<RankingReportsTab
									v-if="hasKey(user, 'users_type')"
									:seo-id="getValue(seo_detail, 'id')"
									:project-id="getValue(seo_detail, 'project.id')"
									:file-name="getValue(seo_detail, 'customer_urls.0.website_urls')"
									:users-type="userType"
								/>
							</v-tab-item>

							<v-tab-item v-if="seoTab == 'keyword_analysis'" value="keyword_analysis">
								<KeywordAnalysis
									:project-id="getValue(seo_detail, 'project.id')"
									v-if="projectId"
									:file-name="getValue(seo_detail, 'customer_urls.0.website_urls')"
								/>
							</v-tab-item>

							<v-tab-item v-if="seoTab == 'keyword_history'" value="keyword_history">
								<KeywordHistory
									v-if="getValue(seo_detail, 'project.id')"
									:seo-id="getValue(seo_detail, 'id')"
									:project-id="hasKey(seo_detail, 'project.id') && getValue(seo_detail, 'project.id')"
									:file-name="getValue(seo_detail, 'customer_urls.0.website_urls')"
								/>
							</v-tab-item>

							<v-tab-item
								v-if="
									seoTab == 'analytics_frame' &&
									hasKey(seo_detail, 'project') &&
									(isMaster == 1 || userType == 'seo')
								"
								value="analytics_frame"
							>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
			</v-layout>
			<v-file-input ref="uploadFile" class="d-none" v-on:change="uploadFile($event)"></v-file-input>
		</div>

		<Dialog :dialog="dialog" :dialog-width="600" @close="closeModal">
			<template v-slot:title> Update Status </template>
			<template v-slot:body>
				<div class="form-grp-title">
					<div>Status {{ dialog_type }}</div>
					<small>
						<v-icon size="16">mdi-progress-question</v-icon> Tell us a reason for {{ dialog_type }}
					</small>
				</div>
				<v-form v-model.trim="formValid">
					<v-row>
						<v-col md="3">
							<label class="">Reason</label>
						</v-col>
						<v-col md="9">
							<TextAreaInput
								hide-details
								dense
								style="margin-top: 0px !important"
								:disabled="pageLoading"
								v-model="remark"
								:loading="pageLoading"
								:placeholder="`Reason for ${dialog_type}`"
								:word-limit="200"
								:rules="[vrules.required(remark, 'Reason')]"
							></TextAreaInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-on:click="
						() => {
							if (dialog_type == 'Hold') {
								updateStatus(2);
							} else if (dialog_type == 'Close') {
								updateStatus(3);
							} else if (dialog_type == 'Approve') {
								approve_reject(1);
							} else if (dialog_type == 'Reject') {
								approve_reject(2);
							}
						}
					"
					color="blue darken-4"
					class="white--text"
					depressed
					tile
					:disabled="pageLoading || !formValid"
				>
					UPDATE
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					color="red"
					class="white--text"
					@click="closeModal"
				>
					No, Close
				</v-btn>
			</template>
		</Dialog>
	</v-sheet>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { toSafeInteger } from "lodash";
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import { GET, POST } from "@/core/services/store/request.module";
import DomainRankingTab from "@/view/module/domain/DomainRankingTab";
import RankingReportsTab from "@/view/module/domain/RankingReportsTab";
import KeywordAnalysis from "@/view/module/domain/CustomerKeywordAnalysis";
import KeywordHistory from "@/view/module/domain/CustomerKeywordHistory";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import { SET_MESSAGE /* , SET_ERROR */ } from "@/core/services/store/common.module";
import objectPath from "object-path";
import MainMixin from "@/core/mixins/main.mixin.js";
import ShowValue from "@/view/components/ShowValue.vue";

export default {
	name: "Leads-details",
	mixins: [MainMixin],
	data() {
		return {
			pageLoading: false,
			search_open: false,
			formValid: true,
			current_rank: {},
			approve_reject_form_valid: true,
			project: {},
			data: {
				credential: [],
				teams: {
					seo_id: null,
					project_id: null,
					sales_owner: null,
					team_lead: null,
					a_team_lead: null,
					on_page: null,
					off_page: null,
					sales_coordinater: null,
					sales_owner_name: null,
					team_lead_name: null,
					a_team_lead_name: null,
					on_page_name: null,
					off_page_name: null,
					sales_coordinater_name: null,
				},
				competitors: ["Competitor1", "Competitor2", "Competitor3", "Competitor4", "Competitor5"],
				kpi: {
					toCustomer: {
						top_3: null,
						top_5: null,
						top_10: null,
						top_20: null,
						top_50: null,
						top_100: null,
						kpi_6_month_top_3: null,
						kpi_6_month_top_5: null,
						kpi_6_month_top_10: null,
						kpi_6_month_top_20: null,
						kpi_6_month_top_50: null,
						kpi_6_month_top_100: null,
					},
				},
				about: {
					business_name: null,
					phone: null,
					email: null,
					address: null,
					CMS: null,
					account_country: null,
					category: null,
					target_country: null,
					other_cms: null,
				},
			},
			open_detail: true,
			seo_detail: {},
			credential: false,
			cancelDialog: false,
			customer: {},
			contract: {},
			maintenance: [],
			projectId: 0,
			file_list: [],
			seoActivityList: [],
			seoCategoryList: [],
			remark: null,
			statusRemark: null,
			status: null,

			statusHold: false,
			statusClose: false,
			rejectDialog: false,
			approveDialog: false,

			dialog: false,
			dialog_type: null,

			seoTeams: {
				sales_owner: {},
				team_lead: {},
			},
			approval_count: 0,
			salesOwner: null,
			user: null,
		};
	},
	components: {
		DomainRankingTab,
		TextAreaInput,
		RankingReportsTab,
		KeywordAnalysis,
		KeywordHistory,
		Dialog,
		ShowValue,
	},
	methods: {
		getAllData(key) {
			this.pageLoading = true;
			const forIteration = key ? { [key]: this.get_apis[key] } : { ...this.get_apis };
			for (const key in forIteration) {
				let url;
				let method = "get";
				if (key == "role") {
					url = this.get_apis[key];
				} else if (key == "credential" || key == "competitors" || key == "about") {
					url = this.get_apis[key] + `/${this.projectId}/${this.seoId}`;
				} else {
					url = this.get_apis[key] + `?project_id=${this.projectId}&seo_id=${this.seoId}`;
				}
				ApiService[method](url).then(({ data }) => {
					if (key == "competitors") {
						this.data[key] = data ? data.competitors.split(",") : this.data[key];
					} else if (key == "kpi") {
						this.data[key]["toCustomer"] = data.to_customer
							? data.to_customer
							: this.data[key]["toCustomer"];
						this.data[key]["toDelhi"] = data.to_delhi ? data.to_delhi : this.data[key]["toDelhi"];
						this.current_rank = data.current_rank;
					} else if (key == "teams") {
						this.data[key] = data ? { ...data } : this.data[key];
					} else if (key == "credential") {
						this.createAbout =
							key == "about" && !data ? true : key == "about" && data ? false : this.createAbout;

						if (this.AllowAnyOforGetRoleNType(["ateam_lead", "on_page", "off_page"])) {
							/* ATL Role can only view and edit below credentials */
							let filteredData = data.filter((cred) => this.ATLPermitCredentials(cred, "data"));
							this.credentialcheckList = this.credentialcheckList.filter((cred) =>
								this.ATLPermitCredentials(cred, "dd")
							);
							this.data[key] = filteredData.length ? filteredData : [];
						} else {
							this.data[key] = data ? data : this.data[key];
						}
					} else {
						this.createAbout =
							key == "about" && !data ? true : key == "about" && data ? false : this.createAbout;
						this.data[key] = data ? data : this.data[key];
					}
				});
			}
			this.pageLoading = false;
		},
		kpiColorIdentifier(half_yr, full_yr, current, month_passed) {
			let color;
			let monthPassed = month_passed;
			let kpi_value;

			if (monthPassed <= 7) {
				kpi_value = half_yr * (monthPassed / 6);
			} else {
				kpi_value = full_yr * (monthPassed / 12);
			}

			if (Math.ceil(kpi_value) <= current) {
				color = "green--text";
			} else {
				color = "red--text";
			}

			return color;
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		backToListing() {
			/* Return back to the listing page */
			let query = this.$route.query;
			this.$router.push({
				name: "customerSeo",
				query: { ...query, t: new Date().getTime() },
			});
		},
		uploadFile(data) {
			const file_size = (data.size / 1024).toFixed(1) + "kb";
			const data_opj = { icon: "mdi-file-check-outline", name: data.name, size: file_size };
			this.file_list.push(data_opj);
		},
		closeModal() {
			this.remark = null;
			this.dialog = false;
			this.status = this.seo_detail.status;
		},
		doAction(status) {
			this.dialog = status == 1 ? false : true;
			switch (status) {
				case 1:
					this.updateStatus(1);
					break;
				case 2:
					this.dialog_type = "Hold";
					break;
				case 3:
					this.dialog_type = "Close";
					break;
				case 4:
					this.dialog_type = "Approve";
					break;
				case 5:
					this.dialog_type = "Reject";
			}
		},
		getBacklinkActivity() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "list-activity" })
				.then((data) => {
					_this.seoActivityList = data.data.map((row) => {
						return { title: row.activity, value: row.activity, id: row.id };
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getBacklinkCategory() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "list-category" })
				.then((data) => {
					_this.seoCategoryList = data.data.map((row) => {
						return { title: row.category, value: row.category, id: row.id };
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getSingleProject() {
			let seoId = toSafeInteger(this.$route.params.id);
			this.$store
				.dispatch(GET, {
					url: `seo/${seoId}`,
				})
				.then((data) => {
					this.seo_detail = { ...data };
					this.status = data.status;
					this.projectId = data.project.id;
					this.getSEOTeams(data.project.id);
					this.approval_count = data.account_approve + data.sales_owner_approve + data.team_lead_approve;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		updateStatus(status) {
			this.pageLoading = true;

			let seoId = toSafeInteger(this.$route.params.id);
			let payload = {
				status,
				remark: this.remark,
			};

			const _this = this;
			_this.$store
				.dispatch(POST, { url: `seo-project/status/${seoId}`, data: payload })
				.then(() => {
					if (status == 1) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Status updated success | Activated",
							},
						]);
					}
					if (status == 2) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Status updated success | Hold",
							},
						]);
					}
					if (status == 3) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Status updated success | Closed",
							},
						]);
					}

					this.getSingleProject();
					this.closeModal();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getSEOTeams(projectId) {
			let seoId = toSafeInteger(this.$route.params.id);
			ApiService.get(`seo-team?project_id=${projectId}&seo_id=${seoId}`).then(({ data }) => {
				this.seoTeams = data ? data : this.seoTeams;
			});
		},
		approve_reject(status_action) {
			let seoId = toSafeInteger(this.$route.params.id);
			this.pageLoading = true;
			let payload = {
				status_action,
				reject_remark: this.remark,
			};
			const _this = this;
			_this.$store
				.dispatch(POST, { url: `seo-project/status-approved/${seoId}`, data: payload })
				.then(({ message }) => {
					this.closeModal();
					this.getSingleProject();
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message,
						},
					]);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		pending_approval() {
			let pending = [];
			if (this.seo_detail?.sales_owner_approve == 0) {
				pending.push("Sales Owner");
			}
			if (this.seo_detail?.team_lead_approve == 0) {
				pending.push("Team Lead");
			}
			if (this.seo_detail?.account_approve == 0) {
				pending.push("Account");
			}
			return pending;
		},
		// approval_status() {
		// 	let status = null;
		// 	if (this.seo_detail?.request_status) {
		// 		if (
		// 			this.seo_detail?.sales_owner_approve == 2 ||
		// 			this.seo_detail?.team_lead_approve == 2 ||
		// 			this.seo_detail?.account_approve == 2
		// 		) {
		// 			status = "reject";
		// 		} else if (
		// 			this.seo_detail?.sales_owner_approve == 1 &&
		// 			this.seo_detail?.team_lead_approve == 1 &&
		// 			this.seo_detail?.account_approve == 1
		// 		) {
		// 			status = "approved";
		// 		}
		// 	}
		// 	return status;
		// },
	},
	computed: {
		...mapGetters(["currentUser", "getProfile", "userType", "isMaster"]),
		seoTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.seoTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "overview";
			},
		},
	},
	watch: {
		getProfile: {
			deep: true,
			immediate: true,
			handler(profile) {
				this.user = profile;
			},
		},
	},
	mounted() {
		this.getSingleProject();
		this.getBacklinkActivity();
		this.getBacklinkCategory();
		this.getAllData();

		const { id } = this.$route.params;

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Seo", disabled: false, href: "/seo" },
			{ text: "Detail", disabled: true, href: `/seo-detail/${id}` },
		]);
		// this.salesOwner = this.$route.params.sales
		// HostingEventBus.$on("refresh", () => {
		// 	this.getSingleProject();
		// });
	},
};
</script>
